import { I18n } from "i18n-js";
import translations from "../../bundles/i18n/translations.json";

// eslint-disable-next-line no-undef
I18n.defaultLocale = piaI18n.defaultLocale;
// eslint-disable-next-line no-undef
I18n.locale = piaI18n.currentLocale;

const i18n = new I18n(translations);
// comment
export default i18n;
