import Appsignal from "@appsignal/javascript";
import { plugin as windowEventsPlugin } from "@appsignal/plugin-window-events";
import { plugin as pathDecoratorPlugin } from "@appsignal/plugin-path-decorator";

const isRunningInJest =
  typeof process !== "undefined" && process.env.JEST_WORKER_ID !== undefined;

const appsignal = new Appsignal({
  key: isRunningInJest
    ? undefined
    : document.head.querySelector(
        "[name~=appsignal_frontend_push_api_key][content]",
      ).content,
  revision: isRunningInJest
    ? undefined
    : document.head.querySelector(
        "[name~=appsignal_frontend_revision][content]",
      ).content,
});

if (!isRunningInJest) {
  appsignal.use(windowEventsPlugin());
  appsignal.use(pathDecoratorPlugin());
}

export { appsignal };
